import modalusaha from "../../public/static/images/svg/contract/workingcapital.svg";
import mpf from "../../public/static/images/svg/contract/multifinancing.svg";
import commodity from "../../public/static/images/svg/contract/commodity.svg";
import paylater from "../../public/static/images/svg/contract/392DCA3E-F666-46C8-B20A-5336BB4B6765.svg";
import proteksi from "../../public/static/images/svg/contract/insurance.svg";
import marketplace from "../../public/static/images/svg/contract/4170B0C6-1301-4EAB-82DC-11778DC85F48.svg";

export const DataProductProfile = {
  PP_WCF: {
    name: "Modal Usaha Home Credit",
    icon: modalusaha
  },
  PP_MPF: {
    name: "Pembiayaan Multiguna",
    icon: mpf
  },
  PP_UNSEC: {
    name: "Belanja di Toko",
    icon: commodity
  },
  PP_PAYLATER_XSELL: {
    name: "Bayar Nanti",
    icon: paylater
  },
  PP_PAYLATER: {
    name: "Bayar Nanti",
    icon: paylater
  },
  PP_SAI: {
    name: "Proteksi",
    icon: proteksi
  },
  PP_OPF_WALLET: {
    name: "Marketplace",
    icon: marketplace
  },
  PP_OPF_B2B: {
    name: "Marketplace",
    icon: marketplace
  },
  PP_ONLINE: {
    name: "Marketplace",
    icon: marketplace
  }
};
